<template>
  <v-expansion-panels hover>
    <RuskealaServerListItem v-for="remain in remains" :key="remain.id" :remain="remain" :user="user" />
  </v-expansion-panels>
</template>

<script>
import RuskealaServerListItem from './RuskealaServerListItem'

export default {
  props: {
    remains: {
      type: Array,
      required: true
    },
    user: {
      type: Object,
      required: false
    }
  },
  components: {
    RuskealaServerListItem
  }
}
</script>
