<template>
  <div class="kit-tasks">
    <h1 class="subtitle-1 grey--text">Остатки</h1>
    <RuskealaServerList :remains="remains" :user="user" />
  </div>
</template>

<script>
import RuskealaServerList from '@/components/remain/RuskealaServerList'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    RuskealaServerList
  },
  computed: {
    projectId() {
      return this.$route.params.projectId
    },
    ...mapGetters({
      remains: 'remain/remains',
      user: 'auth/user'
    })
  },
  mounted() {
    this.fetchRemains({
      projectId: this.projectId
    })
  },
  methods: {
    ...mapActions('remain', ['fetchRemains'])
  }
}
</script>
