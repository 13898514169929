<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="py-1 px-5">
      <v-row>
        <v-col md="3" class=".col">
          <div class="caption grey--text">Название</div>
          <div>{{ remain.description }}</div>
        </v-col>
        <v-col md="1" class=".col">
          <div class="caption grey--text">Сессия</div>
          <div>{{ this.session }}</div>
        </v-col>
        <v-col class=".col">
          <div class="caption grey--text">Поезда</div>
          <div>{{ this.trains }}</div>
        </v-col>
        <v-col md="1" class=".col">
          <div class="caption grey--text">Обновлено</div>
          <div>{{ this.updated }}</div>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div v-if="remain.remains.length > 0">
        <RemainList :remains="remain.remains" :user="user" />
      </div>
      <div v-else>Данные отсутствуют</div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import moment from 'moment'
import RemainList from './RemainList'
import config from '@/config/config'
export default {
  props: {
    remain: Object,
    user: {
      type: Object,
      required: false
    }
  },
  components: {
    RemainList
  },
  filters: {
    format(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    fromNow(date) {
      return moment(date).fromNow()
    }
  },
  computed: {
    trains() {
      const trains = []
      const rem = this.remain.remains
      for (let i = 0; i < rem.length; i++) {
        const trainsArr = JSON.parse(rem[i].trains)
        for (let j = 0; j < trainsArr.length; j++) {
          if (!trains.includes(trainsArr[j])) {
            trains.push(trainsArr[j])
          }
        }
      }
      return trains.toString()
    },
    session() {
      let session = ''
      if (this.remain.remains.length > 0) {
        session = this.remain.remains[0].session
      }
      return session
    },
    updated() {
      let updated = ''
      if (this.remain.remains.length > 0) {
        updated = this.remain.remains[0].updatedAt
      }
      if (updated.length > 0) {
        updated = this.formatDate(updated)
      }
      return updated
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD/MM/YY HH:mm')
    },
    url() {
      return config.pictureBaseUrl
    }
  }
}
</script>
<style>
.col {
  margin: 0;
  padding: 1px;
}
img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.imgContainer {
  width: 90%;
  height: 100px;
}
</style>
