<template>
  <v-expansion-panel>
    <div class="py-1 px-5">
      <v-row>
        <v-col md="1" class=".col">
          <div class="caption grey--text">Изображение</div>
          <div class="imgContainerListItem">
            <img :src="url() + remain.picture" alt="Изображение" />
          </div>
        </v-col>
        <v-col md="1" class=".col">
          <div class="caption grey--text">Артикул</div>
          <div>{{ remain.article }}</div>
        </v-col>
        <v-col class=".col">
          <div class="caption grey--text">Наименование</div>
          <div>{{ remain.name }}</div>
        </v-col>
        <v-col md="1" class=".col">
          <div class="caption grey--text">Цена</div>
          <div>{{ remain.price }}</div>
        </v-col>
        <v-col md="1" class=".col">
          <div class="caption grey--text">Количество</div>
          <div>{{ remain.count }}</div>
        </v-col>
      </v-row>
    </div>
  </v-expansion-panel>
</template>

<script>
import moment from 'moment'
import config from '@/config/config'
export default {
  props: {
    remain: Object,
    user: {
      type: Object,
      required: false
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD/MM/YY HH:mm')
    },
    url() {
      return config.pictureBaseUrl
    }
  }
}
</script>
<style>
.col {
  margin: 0;
  padding: 1px;
}
img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.imgContainerListItem {
  width: 90%;
  height: 35px;
}
</style>
